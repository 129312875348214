@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    font-family: Lexend, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@import url('https://fonts.googleapis.com/css?family=Lexend');
@import url('https://fonts.googleapis.com/css?family=Protest+Strike');

.mapboxgl-ctrl-logo {
    display: none !important;
}

sl-textarea::part(textarea) {
    field-sizing: content;
}

sl-select::part(display-input	),
sl-select::part(listbox) {
    text-transform: capitalize;
}

/* setting wider & taller Shoelace modals for mobile */
@media only screen and (max-width: 768px) {
    .responsive-dialog::part(base) {
        padding: 0 !important;   /* Removes internal padding */
        margin: 0 !important;    /* Removes outer margin */
        inset: 0;                /* Ensures the modal covers the full screen */
        max-width: 100vw;        /* Override max-width restriction */
        max-height: 100vh;       /* Override max-height restriction */
    }

    .responsive-dialog::part(panel) {
        max-width: 98vw;        /* Override max-width restriction */
        max-height: 98vh;       /* Override max-height restriction */
        padding-left: 0;     /* Adds padding to the left */
        padding-right: 0;    /* Adds padding to the right */
        margin-left: 0;
        margin-right: 0;  
    }

    .responsive-dialog::part(body) {
        padding-left: 8px;
        padding-right: 8px;
    }
}

/* custom animation for popups on map hover tooltips */
.popup-animate { animation: popIn 300ms forwards; }
@keyframes popIn {
    0% { opacity: 0; transform: scale(0.5); }
    100% { opacity: 1; transform: scale(1); }
}

/* allow tooltips to hoist above other elements */
.white-tooltip {
    --sl-tooltip-background-color: #ffffff; /* Desired background color */
    --sl-tooltip-color: #333; /* Text color, if needed */
}

.version-ul ul {
    list-style-type: disc;
    padding-left: 1rem;
    @apply text-gray-700;
}

.custom-geolocate-button {
  position: relative;
  z-index: 1000; /* Ensure it's above the map */
  pointer-events: auto; /* Allow button clicks */
  right: -29px;
  top: 15px;
}

.custom-geolocate-button:hover {
  background-color: #F0F0F0 !important;
}

.mapboxgl-map {
  pointer-events: auto; /* Prevent conflicts */
}

.mapboxgl-map .mapboxgl-marker {
  pointer-events: none; /* Prevent markers from blocking clicks */
}


.mapboxgl-ctrl-top-right .mapboxgl-ctrl {
  margin-top: 50px !important;
}

.mapboxgl-popup-close-button {
  padding-right: 10px;
  padding-top: 8px;
  font-size: x-large;
}


/* mapbox current location marker */
.pulse-marker {
  width: 15px;
  height: 15px;
  background-color: #007aff; /* Blue color */
  border-radius: 50%; /* Circular shape */
  box-shadow: 0 0 10px rgba(0, 122, 255, 0.5);
  position: relative;
}

.pulse-marker::after {
  content: '';
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: rgba(0, 122, 255, 0.3);
  position: absolute;
  top: -7.5px;
  left: -7.5px;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(2.5);
    opacity: 0;
  }
}